const axios = require('axios');

let pathname = window.location.pathname;

if (pathname == '/career-listing.html' || pathname == '/contact.html') {

  document.getElementById("contactForm").addEventListener("submit", function (event) {
    event.preventDefault();

    const carrers = document.querySelector('#carrers') && document.querySelector('#carrers').value;

    const source = "Hypercube";
    const name = document.querySelector('#contactName').value;
    const lastName = document.querySelector('#contactLastName').value;
    const email = document.querySelector('#contactEmail').value;
    const message = document.querySelector('#contactMessage') ? document.querySelector('#contactMessage').value : '';
    const receiver = "hello@getobok.com";
    const recaptchaResponse = grecaptcha.getResponse();

    if (recaptchaResponse.length === 0) {
      document.querySelector('#alert-captcha').classList.add('show');
      setTimeout(function () {
        document.querySelector('#alert-captcha').classList.remove('show');
      }, 5000);
    } else {
      document.querySelector('#alert-success').classList.add('show');

      contactForm.reset();

      setTimeout(function () {
        document.querySelector('#alert-success').classList.remove('show');
      }, 13000);

      const mail = {
        source,
        ...carrers && { carrers },
        name,
        lastName,
        email,
        message,
        receiver
      }

      axios.post('https://api.gethypercube.com/send/', mail)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  });
}

