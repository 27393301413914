let cc = initCookieConsent();

cc.run({
  current_lang: 'en',
  force_consent: true,
  autoclear_cookies: true,
  page_scripts: true,

  gui_options: {
    consent_modal: {
      layout: 'cloud',
      position: 'bottom center',
      transition: 'slide',
      swap_buttons: false,
    },
    settings_modal: {
      layout: 'box',
      transition: 'slide',
    }
  },

  languages: {
    'en': {
      consent_modal: {
        title: 'Cookie tracking for the best Hypercube experience.',
        description: 'By selecting "Accept all", you allow Hypercube to use cookies, tags, and similar technologies. We use these technologies to collect your browser information for marketing and functional purposes. <button type="button" data-cc="c-settings" class="cc-link">Read more.</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all',
        }
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        blocks: [
          {
            title: 'Cookie usage 🍪',
            description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy.html" class="cc-link">privacy policy</a>.'
          }, {
            title: 'Strictly necessary cookies',
            description: 'These cookies allow tracking your browser information for marketing purposes.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            }
          },
          {
            title: 'More information',
            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact.html">contact us</a>.',
          }
        ]
      }
    }
  }
});